import gsap from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger.js';

gsap.registerPlugin(ScrollTrigger);

const scrollAnimations = () => {
    const duration = 0.3;
    let section = document.querySelectorAll('[data-animation-section]');
    if (section.length !== false && section) {
        section.forEach((currentSection) => {
            const delay = 0.2;
            let timeBlock = 0;

            setTimeout(() => {
                currentSection.querySelectorAll('[data-animation-reveal]').forEach((elem) => {
                    ScrollTrigger.create({
                        trigger: currentSection,
                        start: getTriggerStart(),
                        once: true,
                        onEnter: function () {
                            if (elem.hasAttribute('data-animation-delay')) {
                                timeBlock = parseFloat(elem.getAttribute('data-animation-delay'));
                            }

                            timeBlock = timeBlock + delay;

                            setAnimation(elem, timeBlock);
                        },
                    });
                });
            }, getTriggerTimer());
        });
    }

    function getTriggerStart() {
        let triggerStart = 'top 100%';
        return triggerStart;
    }

    function getTriggerTimer() {
        let triggerTimer = 150;
        return triggerTimer
    }

    function setAnimation(elem, delay) {
        gsap.to(elem, {
            duration: duration,
            delay: delay,
            x: 0,
            y: 0,
            scale: 1,
            autoAlpha: 1,
            webkitFilter: 'blur(0px)',
            filter: 'blur(0px)',
            ease: 'power1',
            overwrite: 'auto',
            onComplete: function () {
                elem.classList.add('animated')
            },
        });
    }
}

export default scrollAnimations;
