$(document).ready(function() {
    $('.header__menu__btn').on('click',  function () {
        $('body').toggleClass('header__menu__open')
    })

    if ($(window).width() < 1199) {

        $('.menu__inner .menu-item-has-children').append('<span class="menu-item-has-children__overlay"></span>')

        $('.menu-item-has-children__overlay').on('click', function () {
            $(this).removeClass('menu-item-has-children__overlay');
            $(this).parent('.menu-item').toggleClass('menu-item-has-children__open');
        })
    }

    if ($(window).width() > 767) {
        $(window).scroll(function () {
            let scroll = $(window).scrollTop();
            if (scroll > 120) {
                $('.header').addClass('header--fix');
            } else {
                $('.header').removeClass('header--fix');
            }
        });
    }
});
