/* eslint-disable */
const expandableContents = document.querySelectorAll('.expandable-content');
expandableContents.forEach((expandableContent) => {
    if (expandableContent) {
        let expandableToggler = expandableContent.nextElementSibling;

        function showToggle() {
            let fontSize = parseFloat(getComputedStyle(expandableContent).fontSize);
            let expandableOffset = Math.round(expandableContent.offsetHeight / fontSize);
            let expandableScroll = Math.round(expandableContent.scrollHeight / fontSize);

            if (expandableOffset >= expandableScroll) {
                expandableToggler.style.display = "none";
                expandableContent.classList.remove('expandable');
            } else {
                expandableToggler.style.display = null;
                expandableContent.classList.add('expandable');
            }
        }

        showToggle();

        window.addEventListener('resize', function () {
            showToggle();
        });

        if (expandableToggler) {
            expandableToggler.addEventListener('click', function () {
                expandableToggler.classList.toggle('toggled');
                if (expandableToggler.classList.contains('toggled')) {
                    expandableToggler.querySelector('span').textContent = 'Inklappen';
                    expandableContent.style.maxHeight = expandableContent.scrollHeight + 'px';
                    expandableContent.classList.remove('expandable');
                } else {
                    expandableToggler.querySelector('span').textContent = 'Lees meer';
                    expandableContent.style.maxHeight = null;
                    expandableContent.classList.add('expandable');
                }
            });
        }
    }
});

if (document.querySelector('.quickto__select')) {
    document.querySelector('.quickto__select').addEventListener('click', function () {
        this.classList.toggle('toggled');
    });
}

document.querySelectorAll('.quickto button').forEach(button => {
    if (button) {
        button.addEventListener('click', function () {
            let targetContainer = button.attributes['data-target'].value;

            document.querySelector('.quickto__select').classList.remove('toggled');

            document.querySelector(targetContainer).scrollIntoView({
                behavior: 'smooth'
            });
        });
    }
});

$('.specialmoment__media > div').slick({
    dots: false,
    arrows: true,
    swipeToSlide: true,
    slidesToShow: 1,
    nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button">' +
        '<svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="angle-right" class="svg-inline--fa fa-angle-right fa-w-6" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path fill="#E0DD00" d="M166.9 264.5l-117.8 116c-4.7 4.7-12.3 4.7-17 0l-7.1-7.1c-4.7-4.7-4.7-12.3 0-17L127.3 256 25.1 155.6c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0l117.8 116c4.6 4.7 4.6 12.3-.1 17z"></path></svg>\n' +
        '</button>',
    prevArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button">' +
        '<svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="angle-left" class="svg-inline--fa fa-angle-left fa-w-6" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path fill="#E0DD00" d="M25.1 247.5l117.8-116c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L64.7 256l102.2 100.4c4.7 4.7 4.7 12.3 0 17l-7.1 7.1c-4.7 4.7-12.3 4.7-17 0L25 264.5c-4.6-4.7-4.6-12.3.1-17z"></path></svg>\n' +
        '</button>',
});

$('.ourcustomersatisfaction__slider__reviews').slick({
    dots: false,
    arrows: true,
    swipeToSlide: true,
    slidesToShow: 2,
    nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button">' +
        '<svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="angle-right" class="svg-inline--fa fa-angle-right fa-w-6" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path fill="#E0DD00" d="M166.9 264.5l-117.8 116c-4.7 4.7-12.3 4.7-17 0l-7.1-7.1c-4.7-4.7-4.7-12.3 0-17L127.3 256 25.1 155.6c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0l117.8 116c4.6 4.7 4.6 12.3-.1 17z"></path></svg>\n' +
        '</button>',
    prevArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button">' +
        '<svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="angle-left" class="svg-inline--fa fa-angle-left fa-w-6" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path fill="#E0DD00" d="M25.1 247.5l117.8-116c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L64.7 256l102.2 100.4c4.7 4.7 4.7 12.3 0 17l-7.1 7.1c-4.7 4.7-12.3 4.7-17 0L25 264.5c-4.6-4.7-4.6-12.3.1-17z"></path></svg>\n' +
        '</button>',
    responsive: [
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
});
