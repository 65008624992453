$(document).ready(function () {
    // $('.videoblockalt1__holder__play, .videoblock1__holder__play, .videoblock2__holder__play, .videoblock3__holder__play').on('click',  function () {
    //     let videoUrl = $(this).parent().attr('data-video');
    //
    //     if (!$(this).siblings('video').children('source').attr('src')) {
    //         $(this).siblings('video').get(0).load();
    //         $(this).siblings('video').children('source').attr('src', videoUrl);
    //     }
    //
    //     $(this).siblings('video').get(0).play();
    //     $(this).siblings('img').fadeOut();
    //     $(this).fadeOut();
    // });
    //
    // $('.videoblockalt1__holder__video, .videoblock1__holder__video, .videoblock2__holder__video, .videoblock3__holder__video').on('click',  function () {
    //     $(this).siblings('img').fadeIn();
    //     $(this).siblings('button').fadeIn();
    //     $(this).get(0).pause();
    // });

    $('.apply__job__button').on('click', function () {
        $('#quick-apply-form').removeClass('open');
        $('#toggle-apply-form').addClass('open');
    });

    $('.apply__quick__button').on('click', function () {
        $('#toggle-apply-form').removeClass('open');
        $('#quick-apply-form').addClass('open');
    });

    //Check if element is in viewport
    const vacancyLinks = document.querySelector('.vacancy__links');
    const vacancyFixed = document.querySelector('.vacancy__fixed');

    function fadeIn(element, duration = 400) {
        element.style.opacity = 0;
        element.style.display = 'block';
        element.style.transition = `opacity ${duration}ms`;

        setTimeout(() => {
            element.style.opacity = 1;
        }, 10);
    }

    function fadeOut(element, duration = 400) {
        element.style.transition = `opacity ${duration}ms`;
        element.style.opacity = 0;

        setTimeout(() => {
            element.style.display = 'none';
        }, duration);
    }

    if (vacancyLinks && vacancyFixed) {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    fadeOut(vacancyFixed);
                } else {
                    fadeIn(vacancyFixed);
                }
            });
        }, {
            root: null,
            rootMargin: '0px',
            threshold: 0,
        });

        observer.observe(vacancyLinks);
    }
});
