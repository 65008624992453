export default {
    init() {
        // JavaScript to be fired on the home page
    },
    finalize() {
        // JavaScript to be fired on the home page, after the init JS
    },
};

setTimeout(function(){
    $('select[name=map_radius]').wrap('<div class="select-wrapper-radius"></div>');
    $('.categories_filter').append($('.wpgmp_search_form'));
    $('select[name=place_category] option[value]:first').text('Type opvang');
    $('select[name=map_radius] option[value]:first').text('Straal');
    $('.wpgmp_search_input ').attr('placeholder', '1234 AB');
    $('.wpgmp_no_locations').text('Geen resultaten gevonden.');
    $('.front__location__search .categories_filter').append('<a href="locaties/" class="btn btn--secondary btn--zoeklocaties">Zoek locaties</a>');


    if ($(window).width() < 768) {
        $('.location__block__txt').each(function () {
            $(this).appendTo($(this).parent().parent());
        });
    }
}, 200);
