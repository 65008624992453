export default {
    init() {
        // JavaScript to be fired on all pages

        $(document).ready(function () {

            jQuery(function($) {
                $(window).on('scroll', function () {
                    if ($(this).scrollTop() > $(window).height() ) {
                        $('.scroll__top').addClass('active');
                    } else {
                        $('.scroll__top').removeClass('active');
                    }
                });
            });

            $('.scroll__top').click(function() {

                var targetElement = 0;

                if($(this).attr('data-scroll-target')) {
                    targetElement = $('#' + $(this).attr('data-scroll-target')).offset().top - 200;
                }

                $('html, body').animate({
                    scrollTop: targetElement,
                }, 'slow');
                return false;
            });

            // Scroll to section
            $('.scrollToSection').click(function () {
                var targetElement = $(this).attr('data-scroll-target');
                if (targetElement) {
                    $('html, body').animate({
                        scrollTop: $('#' + targetElement).offset().top - 100,
                    }, 'slow');
                }
            });


            function dropdown() {
                let $item = $('.sf-field-taxonomy-taxonomies_vacatures, .sf-field-taxonomy-taxonomies_wijknaam');
                $item.find('ul').wrap( '<div class="filter__dropdown"></div>' );
                $('.filter__dropdown').prepend('<div class="filter__dropdown__name"><div class="filter__dropdown__name__inner"><div class="filter__dropdown__name__text">Selecteer</div> <div class="filter__dropdown__name__icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M360.5 217.5l-152 143.1C203.9 365.8 197.9 368 192 368s-11.88-2.188-16.5-6.562L23.5 217.5C13.87 208.3 13.47 193.1 22.56 183.5C31.69 173.8 46.94 173.5 56.5 182.6L192 310.9l135.5-128.4c9.562-9.094 24.75-8.75 33.94 .9375C370.5 193.1 370.1 208.3 360.5 217.5z"/></svg></div></div></div>')


                $('.filter__dropdown__name').click(function() {
                    $(this).closest('.filter__dropdown').toggleClass('open')
                })

                $('.sf-field-submit').click(function() {
                    $('.filter__dropdown').removeClass('open');
                })
            }

            dropdown();

            $(document).on('sf:ajaxfinish', '.searchandfilter', function(){
                dropdown()
            });


            $('.sf-input-checkbox').click(function() {
                let text = [];
                setTimeout(() => {
                    let getValue = $(this).closest('.filter__dropdown').find('.sf-option-active');
                    let dropdownText = $(this).closest('.filter__dropdown').find('.filter__dropdown__name__text');

                    getValue.each(function() {
                       text.push($(this).find('input').val());
                    })

                    if(text.length > 0) {
                        dropdownText.text(text.join(', '))
                    } else {
                        dropdownText.text('Selecteer')
                    }

                }, 100);
            })

            $('.filter__dropdown').each(function () {
                let text = [];

                let getValue = $(this).find('.sf-option-active');
                let dropdownText = $(this).find('.filter__dropdown__name__text');

                getValue.each(function() {
                   text.push($(this).find('input').val());
                })

                if(text.length > 0) {
                    dropdownText.text(text.join(', '))
                } else {
                    dropdownText.text('Selecteer')
                }

            })

            $(document).on('sf:ajaxfinish', '.searchandfilter', function(){

                $('.sf-input-checkbox').click(function() {
                    let text = [];
                    setTimeout(() => {
                        let getValue = $(this).closest('.filter__dropdown').find('.sf-option-active');
                        let dropdownText = $(this).closest('.filter__dropdown').find('.filter__dropdown__name__text');

                        getValue.each(function() {
                        text.push($(this).find('input').val());
                        })

                        if(text.length > 0) {
                            dropdownText.text(text.join(', '))
                        } else {
                            dropdownText.text('Selecteer')
                        }

                    }, 100);
                })

                $('.filter__dropdown').each(function () {
                    let text = [];

                    let getValue = $(this).find('.sf-option-active');
                    let dropdownText = $(this).find('.filter__dropdown__name__text');

                    getValue.each(function() {
                    text.push($(this).find('input').val());
                    })

                    if(text.length > 0) {
                        dropdownText.text(text.join(', '))
                    } else {
                        dropdownText.text('Selecteer')
                    }

                })
            });



            $(document).mouseup(function(e) {
                let dropdown = $('.filter__dropdown');
                if (!dropdown.is(e.target) && dropdown.has(e.target).length === 0) {
                    dropdown.removeClass('open');
                }
            });


            // Sliders
            $('.banner__slider').slick({
                dots: false,
                infinite: true,
                autoplay: true,
                autoplaySpeed: 3000,
                speed: 500,
                fade: true,
                swipeToSlide: true,
                nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button">' +
                    '<svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="angle-right" class="svg-inline--fa fa-angle-right fa-w-6" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path fill="#E0DD00" d="M166.9 264.5l-117.8 116c-4.7 4.7-12.3 4.7-17 0l-7.1-7.1c-4.7-4.7-4.7-12.3 0-17L127.3 256 25.1 155.6c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0l117.8 116c4.6 4.7 4.6 12.3-.1 17z"></path></svg>\n' +
                    '</button>',
                prevArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button">' +
                    '<svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="angle-left" class="svg-inline--fa fa-angle-left fa-w-6" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path fill="#E0DD00" d="M25.1 247.5l117.8-116c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L64.7 256l102.2 100.4c4.7 4.7 4.7 12.3 0 17l-7.1 7.1c-4.7 4.7-12.3 4.7-17 0L25 264.5c-4.6-4.7-4.6-12.3.1-17z"></path></svg>\n' +
                    '</button>',
            });

            $('.locationgallery').slick({
                dots: false,
                infinite: true,
                speed: 500,
                fade: false,
                autoplay: true,
                slidesToShow: 5,
                centerMode: true,
                centerPadding: '120px',
                swipeToSlide: true,
                nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button">' +
                    '<svg width="17" height="33" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="#ffffff" d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"/></svg>\n' +
                    '</button>',
                prevArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button">' +
                    '<svg width="17" height="33" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="#ffffff" d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"/></svg>' +
                    '</button>',
                responsive: [
                    {
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 4,
                            centerPadding: '70px',
                        },
                    },
                    {
                        breakpoint: 991,
                        settings: {
                            slidesToShow: 3,
                        },
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 2,
                        },
                    },
                    {
                        breakpoint: 575,
                        settings: {
                            slidesToShow: 2,
                            centerPadding: '0',
                        },
                    },
                ],
            });

            var count = $('.locationgallery .slick-slide').length;

            if(count <= 5) {
                $('.locationgallery').addClass('location__slide__none');
            }

            /* eslint-disable */
            $('.testimonial__slider').slick({
                infinite: true,
                speed: 500,
                fade: false,
                swipeToSlide: true,
                autoplay: true,
                slidesToShow: 3,
                arrows: false,
                dots: true,
                responsive: [
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 2
                        }
                    },
                    {
                        breakpoint: 575,
                        settings: {
                            slidesToShow: 1
                        }
                    }
                ]
            });

            // Header shrink on page load
            if ($(document).scrollTop() > 0) {
                $("body").addClass("menu-shrink");
            }

            // Location gallery forcefully moved to right
            //$(".locationgallery .slick-track").css("margin-left", ($(".locationgallery__item").outerWidth() / 2) + "px");

            $("body").click(function (event) {
                if ($('.search__form--show input').is(':focus')) {
                }
                else {
                    $(".search__form").removeClass("search__form--show");
                }
            });

            // Search toggle
            $(".search__form").click(function () {
                $(this).addClass("search__form--show");
                $(this).find('input').focus();
            });

            // Menu Overlay
            $('.menu-toggler').on('click', function () {
                $('body').toggleClass('menu--open');
            });

            // Mobile submenu toggle
            $('.menu ul li.has-submenu').click(function () {
                // $('.menu ul li').removeClass("submenu--open");
                var clicks1 = $(this).data('clicks');
                if (clicks1) {
                    $(this).removeClass("submenu--open");
                } else {
                    $(this).addClass("submenu--open");
                }
                $(this).data("clicks", !clicks1);
            });

            // vacancy Dropdown
            $('.vaccancy__toggle').click(function () {
                var clicks2 = $(this).data('clicks');
                if (clicks2) {
                    $(this).parent(".vaccancyoverview__dropdown").removeClass("vaccancyoverview__dropdown--open");
                } else {
                    $(this).parent(".vaccancyoverview__dropdown").addClass("vaccancyoverview__dropdown--open");
                }
                $(this).data("clicks", !clicks2);
            });

            // vaccancyoverview dropdown selection
            // $('.vaccancyoverview__dropdown__list .block').click(function () {
            //     $(".vaccancy__toggle .block__title").html($(this).find('.block__title').html());
            //     $(".vaccancyoverview__dropdown").removeClass("vaccancyoverview__dropdown--open");
            // });

            $('.parent__dropdown .menu__btn').click(function() {
                console.log('clickerdie clack');
                $('.parent__dropdown__list').toggleClass('show');
            });

            $('.banner__content').first().clone().prependTo('.banner__info');
        });

        // header shrink on page scroll
        $(document).on("scroll", function () {
            if ($(document).scrollTop() > 0) {
                $("body").addClass("menu-shrink");
            } else {
                $("body").removeClass("menu-shrink");
            }
        });

        $(function() {
            setTimeout(function(){
                $('.block').matchHeight();
                $('.block__content').matchHeight();
                $('.block__title').matchHeight();
            }, 300);
        });

        $(document).ajaxSuccess(function(){
            setTimeout(function(){
                $('.block').matchHeight();
                $('.block__content').matchHeight();
                $('.block__title').matchHeight();
            }, 300);
        });

        if(navigator.userAgent.match(/Trident\/7\./)) {
            $('.location__map').addClass('location__map__click');

            $('.location__map').on("click", function () {
               $(this).removeClass('location__map__click');
            });
        }

        // Sticky
        $(".js-sticky-form-button").click(function () {
            $('.js-sticky-form').toggleClass("active");
            $('.js-sticky-form-button').toggleClass("active");
        });

        $(".js-sticky-form-close").click(function () {
            $('.js-sticky-form-button').toggleClass("active");
            $('.js-sticky-form').toggleClass("active");
        });

        function showStickyForm() {
            if ($(window).width() < 991 || $(window).height() < 950) {
                $('.js-sticky-form').removeClass("active");
                $('.js-sticky-form-button').addClass("active");
            } else {
                $('.js-sticky-form').addClass("active");
                $('.js-sticky-form-button').removeClass("active");
            }
        }
        showStickyForm();

        $(window).resize(function() {
            showStickyForm();
        });
    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
